@config "../../tailwind.application.config.js";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

a[href],
input[type='checkbox'],
input[type='submit'],
input[type='image'],
input[type='radio'],
label[for],
select,
button {
  cursor: pointer;
}

button .disabled {
  display: none;
}

button[disabled] .disabled {
  display: initial;
}

button .enabled {
  display: initial;
}

button[disabled] .enabled {
  display: none;
}

.input-bordered {
  @apply border-base-content/20;
}

.select-bordered {
  @apply border-base-content/20;
}

.textarea-bordered {
  @apply border-base-content/20;
}

.btn {
  @apply no-animation;
}

.base-input {
  @apply input input-bordered bg-white;
}

.base-textarea {
  @apply textarea textarea-bordered bg-white rounded-3xl;
}

.base-button {
  @apply btn btn-neutral text-white text-base;
}

.white-button {
  @apply btn btn-outline text-base bg-white border-2;
}

.base-checkbox {
  @apply checkbox rounded bg-white checkbox-sm no-animation;
}

.base-radio {
  @apply radio bg-white radio-sm no-animation;
}

.base-select {
  @apply select base-input w-full font-normal;
}

.tooltip-bottom-end:before {
  transform: translateX(-95%);
  top: var(--tooltip-offset);
  left: 100%;
  right: auto;
  bottom: auto;
}

.tooltip-pre:before {
  white-space: pre;
  text-align: left;
}

.tooltip-bottom-end:after {
  transform: translateX(-25%);
  border-color: transparent transparent var(--tooltip-color) transparent;
  top: var(--tooltip-tail-offset);
  left: 50%;
  right: auto;
  bottom: auto;
}

.autocomplete {
  background: white;
  z-index: 1000;
  font: 14px/22px "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  overflow: auto;
  box-sizing: border-box;
  @apply border border-base-300 mt-1 rounded-md;
}

.autocomplete * {
  font: inherit;
}

.autocomplete > div {
  @apply px-2 py-1 font-normal text-sm;
}

.autocomplete .group {
  background: #eee;
}

.autocomplete > div:hover:not(.group),
.autocomplete > div.selected {
  @apply bg-base-300;
  cursor: pointer;
}

.input-outlined {
  outline-style: solid;
  outline-width: 1px;
  outline-offset: 3px;
  outline-color: hsl(var(--bc) / 0.2);
}

select:required:invalid {
  color: gray !important;
}
